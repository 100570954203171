import React, { useEffect } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
// import ReviewForm from './components/ReviewForm';
// import PopularCourse from './components/PopularCourse';
// import CourseTag from './components/CourseTag';
import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from './styles/course.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';

function UniformDefault() {
    useEffect(() => {
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="School Uniform" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="8" sm="12">
                                <div className="course-details-top">
                                  
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="overview">Primary</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="curriculum">High School</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="instructor">Higher Secondary</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="review">Reviews</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                <div className="heading">
                                        <h4>Primary School</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="">
                                                {/* <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" /> */}
                                                <div className="author-name">
                                                    
                                                    <p>Our school uniform is more than just attire; it's a symbol of pride, unity, and professionalism. Designed with both style and comfort in mind, it consists of a light blue shirt, navy blue trousers or skirt, and a dark gray blazer, offering a polished and cohesive look. </p>
                                                </div>
                                            </div>
                                            {/* <div className="category">
                                                <h6>Category</h6>
                                                <p>Social Science</p>
                                            </div> */}
                                            {/* <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                            {/* <div className="price">
                                                <h6>Price</h6>
                                                <p>$29.00</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" />
                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="curriculum" className="overview-tab">
                                                <div className="heading">
                                        <h4>Primary School</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="">
                                                {/* <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" /> */}
                                                <div className="author-name">
                                                    
                                                    <p>Our school uniform is more than just attire; it's a symbol of pride, unity, and professionalism. Designed with both style and comfort in mind, it consists of a light blue shirt, navy blue trousers or skirt, and a dark gray blazer, offering a polished and cohesive look. </p>
                                                </div>
                                            </div>
                                            {/* <div className="category">
                                                <h6>Category</h6>
                                                <p>Social Science</p>
                                            </div> */}
                                            {/* <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                            {/* <div className="price">
                                                <h6>Price</h6>
                                                <p>$29.00</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" />
                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="instructor" className="instructor-tab">
                                                <div className="heading">
                                        <h4>Higher Secondary</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="">
                                                {/* <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" /> */}
                                                <div className="author-name">
                                                    {/* <h6>Author</h6> */}
                                                    <p>Our school uniform is more than just attire; it's a symbol of pride, unity, and professionalism. Designed with both style and comfort in mind, it consists of a light blue shirt, navy blue trousers or skirt, and a dark gray blazer, offering a polished and cohesive look.</p>
                                                </div>
                                            </div>
                                            {/* <div className="category">
                                                <h6>Category</h6>
                                                <p>Social Science</p>
                                            </div> */}
                                            {/* <div className="rating"> */}
                                                {/* <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                            {/* <div className="price">
                                                <h6>Price</h6>
                                                <p>$29.00</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" />
                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="review" className="review-tab">
                                                    <Row>
                                                    <div className="heading">
                                        <h4>Javascript Programming From Scretch For Beginers To Advanced With Top Rated Author (Andy Robert).</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" />
                                                <div className="author-name">
                                                    <h6>Author</h6>
                                                    <p>Andy Robert</p>
                                                </div>
                                            </div>
                                            <div className="category">
                                                <h6>Category</h6>
                                                <p>Social Science</p>
                                            </div>
                                            <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div>
                                            <div className="price">
                                                <h6>Price</h6>
                                                <p>$29.00</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" />
                                    </div>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="4" sm="12">
                                <div className="single-details-sidbar">
                                    <Row>
                                    <Col md="12">
    <div className="course-details-feature">
        <h5 className="title">Uniform Details</h5>
        <ul className="list-unstyled feature-list">
         
            <li><i className="las la-male"></i> Monday/Friday: <span>Cream Shirt</span></li>
            <li><i className="las la-male"></i> Tuesday: <span>Cream T-Shirt</span></li>
            <li><i className="las la-male"></i> Wednesday: <span>Grey T-Shirt</span></li>
            <li><i className="las la-male"></i> Thursday: <span>House T-Shirt</span></li>
        </ul>
        {/* <button type="button" className="enroll-btn">View More</button> */}
    </div>
    </Col>
                                    <Col md="12">
    <div className="course-details-feature mt-5">
        <h5 className="title">Uniform Details</h5>
        <ul className="list-unstyled feature-list">
         
            <li><i className="las la-male"></i> Monday/Friday: <span>Cream Shirt</span></li>
            <li><i className="las la-male"></i> Tuesday: <span>Cream T-Shirt</span></li>
            <li><i className="las la-male"></i> Wednesday: <span>Grey T-Shirt</span></li>
            <li><i className="las la-male"></i> Thursday: <span>House T-Shirt</span></li>
        </ul>
        {/* <button type="button" className="enroll-btn">View More</button> */}
    </div>


</Col>


                                 
                                         {/* <Col md="12">
                                       <UniformDetails />
                                        </Col> */}
                                        {/* <Col md="12">
                                            <CourseTag />
                                        </Col>  */}
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

        </div >
    )
}

export default UniformDefault