import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/productDetails.js";
import feesData from "../../constants/table.js";


class Fee extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper product-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Fee Structure" />

          {/* Product Details */}
          <section className="product-details-area">
            <Container>
              <Row>
                <Col md="12">
                  <div className="product-information">
                    <div className="product-title">
                      <h4>Xiaomy LED Light Bulb With Verious Model</h4>
                    </div>

                    <div className="product-desc">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquam et dolorem nostrum quos placeat. Quibusdam
                        necessitatibus adipisci dignissimos autem voluptate,
                        dicta ullam, assumenda qui, corporis ut vero neque
                        sapiente possimus.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="product-tab">
                    <div className="additional-tab">
                      <Table className="table table-bordered">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center">
                              The Amount is payable at the time of admission is
                              as below:
                            </th>
                          </tr>
                          <tr>
                            <th>No</th>
                            <th>Description</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        {feesData.map((item) => (
                          <tbody key={item?.id}>
                            <tr>
                              <td>{item?.id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.amount}</td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Fee;
