import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/club.js';

// Content Mapping
const clubContent = {
    clubs: { title: "Clubs", description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos itaque atque ullam iste temporibus, laudantium reprehenderit doloremque hic aliquam repellat modi et quam nemo eos alias, maxime nobis necessitatibus optio!orem ipsum dolor sit amet, consectetur adipisicing elit. Quos itaque atque ullam iste temporibus, laudantium reprehenderit doloremque hic aliquam repellat modi et quam nemo eos alias, maxime nobis necessitatibus optio!",title2: "Lion Clubs", description2: "l" },
    sports: { title: "Sports", description: "Engage in sports activities and develop teamwork and fitness." },
    ncc: { title: "NCC", description: "Join the National Cadet Corps to develop leadership and patriotism." },
    nss: { title: "NSS", description: "Be a part of the National Service Scheme and contribute to social causes." },
    drawing: { title: "Drawing Class", description: "Enhance your artistic skills with our drawing classes." }
};

const CurriculumDetails = () => {
    const { id } = useParams(); // Get id from URL
    const [content, setContent] = useState(clubContent["clubs"]); // Default Content
    useEffect(() => {
        if (clubContent[id]) {
            setContent(clubContent[id]); // Update content when id changes
        } else {
            setContent(clubContent["clubs"]); // Fallback to "clubs"
        }
    }, [id]);

    return (
        <Styles>
            <div className="main-wrapper instructor-details-page">
                <HeaderTwo />
                <BreadcrumbBox title={content.title} />

                <section className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/500x500.jpg`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title}</h5>
                                    </div>
                                    <p>{content.description}</p>
                                </div>
                            </Col>
                         
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title}</h5>
                                    </div>
                                    <p>{content.description}</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/500x500.jpg`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <FooterTwo />
            </div>
        </Styles>
    );
};

export default CurriculumDetails;
