import React, { Component } from "react";


import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";

import HeaderTwo from "../../components/HeaderTwo.js";
import FooterTwo from "../../components/FooterTwo.js";


class Lab extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcrumb */}
          <BreadcrumbBox title="Lab" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Well-Equipped School Lab for Hands-On Learning</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-flask"></i> Well-Equipped Science
                          Lab
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-cogs"></i> Practical Hands-On
                          Learning
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-vials"></i> Advanced Lab
                          Equipment & Tools
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/Home/labb.webp`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>School Lab Overview</h5>
                      <p>
                        Our school lab is a well-equipped space for hands-on
                        learning and experimentation. It supports students in
                        understanding scientific principles through practical
                        application. With a variety of tools and resources, the
                        lab provides a safe environment for students to explore
                        subjects like physics, chemistry, biology, and
                        technology. Equipped with modern facilities, it
                        encourages critical thinking, problem-solving, and
                        creativity.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A fully equipped science lab for practical learning
                            in subjects like
                          </span>
                          <br />
                          <span>
                            physics, chemistry, biology, and technology.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Access to a range of scientific equipment and tools
                            for conducting
                          </span>
                          <br />
                          <span>
                            experiments and projects to enhance student
                            learning.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A safe and well-maintained space designed to
                            encourage exploration
                          </span>
                          <br />
                          <span>
                            and foster scientific curiosity in a hands-on
                            environment.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Dedicated areas for both individual and group-based
                            projects, where
                          </span>
                          <br />
                          <span>
                            students can collaborate and apply their knowledge
                            practically.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12"></Col>
                      <Col md="12">
                        {/* <PopularLab /> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Lab;
