import React from 'react';

import { Styles } from './styles/admission';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';

const Admission = () => {
    const admissionSteps = [
        {
            id: 1,
            title: "Application Submission",
            steps: [
                "Fill out the online application form completely",
                "Pay the application processing fee",
                "Upload required documents (birth certificate, previous school records)"
            ]
        },
        {
            id: 2,
            title: "Entrance Assessment",
            steps: [
                "Schedule entrance test appointment",
                "Complete written assessment in core subjects",
                "Participate in student interview session"
            ]
        },
        {
            id: 3,
            title: "Document Verification",
            steps: [
                "Submit original documents for verification",
                "Provide parent's identification proof",
                "Present residence proof and photographs"
            ]
        },
        {
            id: 4,
            title: "Parent Interview",
            steps: [
                "Schedule parent interview with school administration",
                "Discuss student's academic history and interests",
                "Learn about school's educational philosophy"
            ]
        },
        {
            id: 5,
            title: "Admission Confirmation",
            steps: [
                "Receive admission offer letter",
                "Pay admission and term fees",
                "Complete health and medical forms"
            ]
        }
    ];

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper admission-process-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcrumb */}
                <BreadcrumbBox title="Admission Process" />

                {/* Admission Process Area */}
                <section className="admission-process-area">
                    <Styles>
                        <div className="admission-page">
                            <div className="process-area">
                                <div className="container">
                                    <div className="section-title">
                                        <h1>School Admission Process</h1>
                                        <p>Follow these steps to enroll your child in our institution</p>
                                    </div>

                                    {admissionSteps.map((section) => (
                                        <div key={section.id} className="process-section">
                                            <div className="section-header">
                                                <h3>Step {section.id}: {section.title}</h3>
                                            </div>
                                            <div className="process-list">
                                                <ul>
                                                    {section.steps.map((step, index) => (
                                                        <li key={index}>
                                                            <div className="step-number">{index + 1}</div>
                                                            <div className="step-content">{step}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="admission-note">
                                        <h4>Important Information:</h4>
                                        <p>Admission is subject to seat availability and successful completion of all steps. 
                                           For any queries, please contact the admission office.</p>
                                        <p>To apply online, visit: <a href="https://mysticalrose.dschoolapp.com/admission" target="_blank" rel="noopener noreferrer">Mystical Rose Admission Portal</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Styles>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    );
};

export default Admission;
