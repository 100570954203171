import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";

import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/eventDetails.js";
import AwardsCard from "../../components/Awards.js";

class Awards extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper course-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Achievements & Awards" />

          {/* Event Details Area */}
          <section className="course-list-area">
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12">
                  <div className="course-items2">
                    <AwardsCard />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Awards;
