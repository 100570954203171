import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseItemList from '../courses/components/CourseItemsList.js';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';

class CourseList extends Component {

    render() {
        return (
            <div className="main-wrapper course-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Courses" />

                <Styles>
                    {/* Course Grid */}
                    <section className="course-list-area">
                        <Container>
                            <Row>
                               
                                <Col lg="12" md="12" sm="12">
                                    <div className="course-items2">
                                        <Row>
                                            <CourseItemList />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        )
    }
}

export default CourseList