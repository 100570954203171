import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";

import HeaderTwo from "../../components/HeaderTwo.js";
import FooterTwo from "../../components/FooterTwo.js";

class Ground extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcrumb */}
          <BreadcrumbBox title="Ground" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Encouraging Physical Discipline Through Sports</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-running"></i> Athletics & Track
                          Events Collection
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-futbol"></i> Football & Other
                          Sports Access
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-dumbbell"></i>Fitness & Training
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL + `/assets/Home/ground.webp`
                        }
                        alt="Ground"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Ground Overview</h5>
                      <p>
                        Our school ground is dedicated to fostering physical
                        fitness, discipline, and teamwork among students. The
                        open space is well-equipped for various sports,
                        including football, athletics, and outdoor activities,
                        allowing students to develop strength, endurance, and
                        coordination while engaging in healthy competition.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>Spacious ground for football,</span>
                          <br />
                          <span>athletics, and team sports</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Facilities for warm-up exercises, fitness drills,
                          </span>
                          <br />
                          <span>and structured training programs</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Safe and well-maintained environment for students to
                          </span>
                          <br />
                          <span>engage in physical activities</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Special physical education sessions designed for
                            primary students to
                          </span>
                          <br />
                          <span>
                            encourage movement, coordination, and teamwork in a
                            fun way.
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-overview">
                      {/* <h5>Library Committee</h5> */}
                      {/* <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p> */}
                      {/* <div>
      <ul className="list-unstyled">
        <li>
          <i className="fa">1</i>
          <span className="font-weight-bold"> Dr. Arun Kumar </span>
          <br />
          <span>Chairman</span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">2</i>
          <span> Dr. Priya Sharma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">3</i>
          <span> Mr. Rajesh Verma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">4</i>
          <span> Ms. Anjali Nair </span>
        </li>
      </ul>
    </div> */}
                    </div>
                  </div>
                </Col>

                {/* <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <ul className="list-unstyled event-info-list">
                          <li>Collection: <span>50k+ Books</span></li>
          <li>Digital Access: <span>E-books & Journals</span></li>
          <li>Study Areas: <span>Private & Group</span></li>
          <li>Technology: <span>Catalog</span></li>
          <li>Membership: <span>For All Students</span></li>
          <li>Resources: <span>Research Materials</span></li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularGround/>
                      </Col>
                    </Row>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Ground;
