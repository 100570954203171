import React, { Component } from "react";
import Datas from "../../data/instructor/instructor.json";

import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";

import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/instructor.js";

class Leadership extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper instructor-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="PTA Members" />

          {/* Instructor Area */}
          <section className="instructor-area">
            <Container>
              {Datas.map((data, i) => (
                <Row key={i}>
                  <Col sm="12">
                    <div className="instructor-course-title">
                      <h5>{data?.title}</h5>
                    </div>
                  </Col>

                  {data?.members?.map((item) => (
                    <Col lg="3" md="3" sm="6" key={item?.personName}>
                      <div className="instructor-item">
                        <img
                          src={`/assets/Home/${item.personImage}`}
                          alt=""
                          className="img-fluid"
                        />

                        <div className="img-content text-center">
                          <h5>{item.personName}</h5>
                          <p>{item.personTitle}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ))}
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Leadership;
