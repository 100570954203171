import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerTwo.js";
import { navLinks } from "../constants/layout.js";

class HeaderTwo extends Component {
  render() {
    return (
      <Styles>
        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="3">
                <div className="logo">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/Home/logo1.png"}
                      alt=""
                    />
                  </Link>
                </div>
              </Col>
              <Col md="9">
                <div className="menu-box d-flex justify-content-end">
                  <ul className="nav menu-nav">
                    {navLinks?.map((item) => (
                      <li key={item?.name} className="nav-item dropdown active">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={`${item?.href}`}
                          data-toggle="dropdown"
                        >
                          {item?.name}
                          {item?.subMenu && (
                            <i className="las la-angle-down"></i>
                          )}
                        </Link>
                        {item?.subMenu && (
                          <ul className="dropdown list-unstyled">
                            {item?.subMenu?.map((subItem) => (
                              <li
                                className="nav-item active"
                                key={subItem?.name}
                              >
                                <Link
                                  className="nav-link"
                                  to={`${process.env.PUBLIC_URL}${subItem?.href}`}
                                >
                                  {subItem?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  {/* <div className="search-box">
                                        <Search />
                                    </div> */}
                  {/* <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/registration"}><i className="las la-clipboard-list"></i>Apply Now</Link>
                                    </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu navLinks={navLinks} />

        {/* Mobile Menu */}
        <MobileMenu navLinks={navLinks} />
      </Styles>
    );
  }
}

export default HeaderTwo;
