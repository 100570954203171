import React, { useEffect } from "react";
import Datas from "../data/footer/footer2.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerTwo.js";

function FooterTwo() {
  useEffect(() => {
    const form = document.getElementById("form4");
    const email = document.getElementById("email4");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();

      const emailValue = email.value.trim();

      if (emailValue === "") {
        setError(email, "Email can't be blank");
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
      } else {
        setSuccess(email);
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".input-msg4");
      formControl.className = "form-control error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Footer Two */}
      <footer
        className="footer2"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Home/${Datas.backgroundImage})`,
        }}
      >
        <Container>
          <Row>
            <Col md="3">
              <div className="footer-logo-info">
                <img
                  src={process.env.PUBLIC_URL + "/assets/Home/logo1.png"}
                  alt=""
                  className="img-fluid"
                />
                <p>
                  Mystical Rose Our School fosters academic excellence and
                  personal growth in a supportive environment. With dedicated
                  faculty and modern facilities, we nurture creativity, critical
                  thinking, and a love for learning.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="las la-map-marker"></i>Vettucaud, Titanium
                    Trivadrum ,695021
                  </li>
                  <li>
                    <i className="las la-envelope"></i>
                    rosamysticalcbse@gmail.com
                  </li>
                  <li>
                    <i className="las la-phone"></i>+9496712142
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5>Useful Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/course"}>
                      <i className="las la-angle-right"></i>Admission
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/school"}>
                      <i className="las la-angle-right"></i>About
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/ground"}>
                      <i className="las la-angle-right"></i>Facilitys
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/gallery"}>
                      <i className="las la-angle-right"></i>Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      <i className="las la-angle-right"></i>Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-post">
                <h5>Follow Us</h5>
                {/* <div className="post-box d-flex">
                  <div className="po-icon">
                    <i className="fab fa-facebook-f"></i>
                  </div>
                  <div className="po-content">
                    <a
                      href="https://www.facebook.com/yourpage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Like us on Facebook
                    </a>
                  </div>
                </div> */}
                {/* <div className="post-box d-flex">
                  <div className="po-icon">
                    <i className="fab fa-instagram"></i>
                  </div>
                  <div className="po-content">
                    <a
                      href="https://www.instagram.com/yourpage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Follow us on Instagram
                    </a>
                  </div>
                </div> */}
                <div className="post-box d-flex">
                  <div className="po-icon">
                    <i className="fab fa-youtube"></i>
                  </div>
                  <div className="po-content">
                    <a
                      href="https://www.youtube.com/@mysticalroseschool1042"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subscribe on YouTube
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="f-newsletter">
                <h5>Newsletter</h5>
                <p>
                  Stay updated with the latest news, events, and announcements
                  from Mystical Rose Convent School.
                </p>

                <form id="form4" className="form">
                  <p className="form-control">
                    <input
                      type="email"
                      placeholder="Enter email here"
                      id="email4"
                    />
                    <span className="input-msg4"></span>
                  </p>
                  <button>Submit</button>
                </form>
              </div>
            </Col>
            <Col md="12">
              <div className="copytext-area text-center">
                <p>
                  Copyright &copy; 2025 | Designed With{" "}
                  <i className="las la-heart"></i> by{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mystical Rose
                  </a>
                </p>
                {/* <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default FooterTwo;
