import React, { Component, Fragment } from "react";
import Datas from "../data/awards/item.json";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

class AwardsCard extends Component {
  render() {
    return (
      <Fragment>
        {/* Course Item */}
        {Datas.map((data, i) => (
          <Col md="12" key={i}>
            <div className={`course-item d-flex ${(i+1) % 2 === 0 ? 'reverse' : ''}`}>
              <div className="course-image-box">
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})`,
                  }}
                >
                  <div className="author-img d-flex">
                    <div className="img">
                      <Link to={process.env.PUBLIC_URL + data.courseLink}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/${data.authorImg}`
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-content">
                <h6 className="heading">
                  <Link to={process.env.PUBLIC_URL + data.courseLink}>
                    {data.courseTitle}
                  </Link>
                </h6>

                <p className="desc">{data.courseDesc}</p>
                {/* <Link className="details-btn" to={process.env.PUBLIC_URL + data.courseLink}>View Details</Link> */}
              </div>
            </div>
          </Col>
        ))}
      </Fragment>
    );
  }
}

export default AwardsCard;
