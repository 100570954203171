import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeTwo from "./HomeTwo";

import CourseGrid from "./pages/courses/CourseGrid";
import CourseList from "./pages/courses/CourseList";
import CourseDetails from "./pages/courses/CourseDetails";
import Instructor from "./pages/instructor/Instructors";
import InstructorDetails from "./pages/instructor/InstructorDetails";
import Gallery from "./pages/gallery/Gallery";
import Events from "./pages/events/Events";
import EventDetails from "./pages/events/EventsDetails";
import Login from "./pages/account/Login";
import Register from "./pages/account/Register";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import PageNotFound from "./pages/404/PageNotFound";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import BlogClassic from "./pages/blog/BlogClassic";
import BlogGrid from "./pages/blog/BlogGrid";
import BlogDetails from "./pages/blog/BlogDetails";
import Product from "./pages/shop/Products";
import ProductDetails from "./pages/shop/ProductDetails";
import Cart from "./pages/shop/Cart";
import School from "./pages/about/About";
import History from "./pages/about/History.js";
import Mission from "./pages/about/Mission.js";
import Leadership from "./pages/about/Leadership.js";
import Awards from "./pages/about/Awards.js";
import Course from "./pages/academics/Course.js";
import Fee from "./pages/academics/Fee.js";
import UniformDefault from "./pages/academics/Uniform.js";
import Rules from "./pages/academics/Rule.js";
import Library from "./pages/facilities/Library.js";
import Lab from "./pages/facilities/Lab.js";
import Auditorium from "./pages/facilities/Auditorium.js";
import Ground from "./pages/facilities/Ground.js";
import Transportation from "./pages/facilities/Transportation.js";
import SmartClass from "./pages/facilities/SmartClass";
import Curriculum from "./pages/curriculum/Curriculum";
import Policy from "./pages/about/Policy.js";
import Admission from "./pages/academics/Admission.js";
import CurriculumDetails from "./pages/curriculum/CurriculamDeatil.js";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={HomeTwo}
        />
        {/* <Route path={`${process.env.PUBLIC_URL + "/"}`} component={HomeTwo} /> */}
        <Route
          path={`${process.env.PUBLIC_URL + "/school"}`}
          component={School}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/event"}`}
          component={History}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/mission"}`}
          component={Mission}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/policy"}`}
          component={Policy}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/leadership"}`}
          component={Leadership}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/awards"}`}
          component={Awards}
        />
        <Route path={`${"/curriculum"}`} component={Curriculum} />
        <Route path={`${"/curriculum/:id"}`} component={CurriculumDetails} />
        <Route path={`${"/fee"}`} component={Fee} />

        <Route
          path={`${process.env.PUBLIC_URL + "/course-grid"}`}
          component={CourseGrid}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/course-list"}`}
          component={CourseList}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/course-details"}`}
          component={CourseDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/instructor"}`}
          component={Instructor}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/instructor-details"}`}
          component={InstructorDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/gallery"}`}
          component={Gallery}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/events"}`}
          component={Events}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/event-details"}`}
          component={EventDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/login"}`}
          component={Login}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/registration"}`}
          component={Register}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/contact"}`}
          component={Contact}
        />
        <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
        <Route
          path={`${process.env.PUBLIC_URL + "/404"}`}
          component={PageNotFound}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
          component={ComingSoon}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-classic"}`}
          component={BlogClassic}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
          component={BlogGrid}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-details"}`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/products"}`}
          component={Product}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/product-details"}`}
          component={ProductDetails}
        />
        <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />
        <Route
          path={`${process.env.PUBLIC_URL + "/course"}`}
          component={Course}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/uniform"}`}
          component={UniformDefault}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/rules"}`}
          component={Rules}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/library"}`}
          component={Library}
        />
        <Route path={`${process.env.PUBLIC_URL + "/lab"}`} component={Lab} />
        <Route
          path={`${process.env.PUBLIC_URL + "/auditorium"}`}
          component={Auditorium}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/ground"}`}
          component={Ground}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/transportation"}`}
          component={Transportation}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/smartclass"}`}
          component={SmartClass}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/admission"}`}
          component={Admission}
        />
      </Switch>
    </Router>
  );
}

export default App;
