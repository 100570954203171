import React, { Component } from "react";


import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";

import HeaderTwo from "../../components/HeaderTwo.js";
import FooterTwo from "../../components/FooterTwo.js";


class Transportation extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcrumb */}
          <BreadcrumbBox title="Transportation" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>
                        Reliable School Bus Services for Safe and Convenient
                        Transportation
                      </h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-bus"></i> Local Area Coverage
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-user-tie"></i> Expert & Skilled
                          Drivers
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-shield-alt"></i> Student Safety
                          Prioritized
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/Home/school-bus.webp`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Transportation Overview</h5>
                      <p>
                        Our school transportation service ensures safe and
                        convenient travel for students. The buses are designed
                        to provide a comfortable experience while adhering to
                        the highest safety standards. With skilled drivers and
                        well-maintained vehicles, we make sure that students
                        have a reliable and timely mode of transportation to and
                        from school.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Well-maintained buses covering local areas near the
                            school for easy
                          </span>
                          <br />
                          <span>and convenient student transportation.</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Skilled and experienced drivers dedicated to
                            ensuring safe travel
                          </span>
                          <br />
                          <span>for all students on their daily commute.</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A strong focus on student safety, including regular
                            vehicle checks
                          </span>
                          <br />
                          <span>and safety measures during travel.</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Timely and reliable transportation ensuring students
                            arrive at school
                          </span>
                          <br />
                          <span>and return home without delays.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12"></Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Transportation;
