export const navLinks = [
  {
    id: "home",
    name: "Home",
    href: "/",
  },
  {
    id: "about",
    name: "About",
    href: "#",
    subMenu: [
      { id: "school", name: "The School", href: "/school" },
      { id: "history", name: "Events", href: "/event" },
      { id: "mission", name: "Mission & Vision", href: "/mission" },
      // { id: "policies", name: "Policies", href: "/policy" },
      { id: "leadership", name: "Leadership Team / Management", href: "/leadership" },
      
    ],
  },
  {
    id: "academics",
    name: "Academics",
    href: "#",
    subMenu: [
      { id: "academics", name: "Academics", href: "/course" },
      // { id: "curriculum", name: "Curriculum", href: "/curriculum" },
      // { id: "fee", name: "Fee Structure", href: "/fee" },
      // { id: "uniform", name: "School Uniform", href: "/uniform" },
      { id: "rules", name: "Rules & Regulations", href: "/rules" },
      { id: "admission", name: "Admission", href: "/admission" },
    ]
  },
  {
    id: "facilities",
    name: "Facilities",
    href: "#",
    subMenu: [
      { id: "ground", name: "Ground", href: "/ground" },
      { id: "library", name: "Library", href: "/library" },
      { id: "auditorium", name: "Auditorium", href: "/auditorium" },
      { id: "lab", name: "Lab", href: "/lab" },
      { id: "transportation", name: "Transportation", href: "/transportation" },
      
    ]
  },
  // {
  //   id: "other",
  //   name: "Other",
  //   href: "#",
  //   subMenu: [
  //     { id: "clubs", name: "Clubs", href: "/curriculum/clubs" },
  //     { id: "sports", name: "Sports", href: "/curriculum/sports" },
  //     { id: "ncc", name: "NCC", href: "/curriculum/ncc" },
  //     { id: "nss", name: "NSS", href: "/curriculum/nss" },
  //     { id: "drawing", name: "Drawing Class", href: "/curriculum/drawing" },
  //   ]
  // },
  {
    id: "gallery",
    name: "Gallery",
    href: "/gallery",
  },
  {
    id: "contact",
    name: "Contact",
    href: "/contact",
  },
];
