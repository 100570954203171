import React, { Component } from 'react';
import Datas from '../../data/instructor/details.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';

class Policy extends Component {
    render() {
        const settings = {
            slidesPerView: 3,
            loop: false,
            speed: 1000,
            autoplay: false,
            spaceBetween: 30,
            watchSlidesVisibility: false,
          
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Policy" />

                    {/* Instructor Details Area */}
                    <section className="instructor-details-area">
                        <Container>
                            <Row>
                               
                            
                                <Col md="12">
                                    <div className="instructor-course-title">
                                        <h5>Courses by Kamal Sulaiman</h5>
                                    </div>
                                    <div className="instructor-course-slider">
                                        <Swiper {...settings}>
                                            {
                                                Datas.map((data, i) => (
                                                    <div className="course-item" key={i}>
                                                        <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                            {/* <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}> */}
                                                                <div className="author-img d-flex">
                                                                    {/* <div className="img">
                                                                        <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                                    </div> */}
                                                                    {/* <div className="title">
                                                                        <p>{data.authorName}</p>
                                                                        <span>{data.authorCourses}</span>
                                                                    </div> */}
                                                                </div>
                                                                {/* <div className="course-price">
                                                                    <p>{data.price}</p>
                                                                </div> */}
                                                            {/* </div> */}
                                                        </Link>
                                                        <div className="course-content">
                                                            <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link></h6>
                                                            <p className="desc">{data.courseDesc}</p>
                                                            <div className="course-face d-flex justify-content-between">
                                                                {/* <div className="duration">
                                                                    <p><i className="las la-clock"></i>120</p>
                                                                </div> */}
                                                                {/* <div className="rating">
                                                                    <ul className="list-unstyled list-inline">
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                        </li>
                                                                        <li className="list-inline-item">(4.5)</li>
                                                                    </ul>
                                                                </div> */}
                                                                {/* <div className="student">
                                                                    <p><i className="las la-chair"></i>60</p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="instructor-course-title">
                                        <h5>Courses by Kamal Sulaiman</h5>
                                    </div>
                                    <div className="instructor-course-slider">
                                        <Swiper {...settings}>
                                            {
                                                Datas.map((data, i) => (
                                                    <div className="course-item" key={i}>
                                                        <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                            {/* <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}> */}
                                                                <div className="author-img d-flex">
                                                                    {/* <div className="img">
                                                                        <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                                    </div> */}
                                                                    {/* <div className="title">
                                                                        <p>{data.authorName}</p>
                                                                        <span>{data.authorCourses}</span>
                                                                    </div> */}
                                                                </div>
                                                                {/* <div className="course-price">
                                                                    <p>{data.price}</p>
                                                                </div> */}
                                                            {/* </div> */}
                                                        </Link>
                                                        <div className="course-content">
                                                            <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link></h6>
                                                            <p className="desc">{data.courseDesc}</p>
                                                            <div className="course-face d-flex justify-content-between">
                                                                {/* <div className="duration">
                                                                    <p><i className="las la-clock"></i>120</p>
                                                                </div> */}
                                                                {/* <div className="rating">
                                                                    <ul className="list-unstyled list-inline">
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                        </li>
                                                                        <li className="list-inline-item">(4.5)</li>
                                                                    </ul>
                                                                </div> */}
                                                                {/* <div className="student">
                                                                    <p><i className="las la-chair"></i>60</p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Policy