import React, { Component } from "react";


import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";


import HeaderTwo from "../../components/HeaderTwo.js";
import FooterTwo from "../../components/FooterTwo.js";

class Library extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcrumb */}
          <BreadcrumbBox title="Library" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Comprehensive Library for Academic Excellence</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-book"></i> Extensive Book
                          Collection
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Digital Library
                          Access
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-user-graduate"></i> Research &
                          Study Areas
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/Home/library (1).webp`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Library Overview</h5>
                      <p>
                        Our school library is a dedicated space for students to
                        read, learn, and explore various subjects. It offers a
                        well-organized collection of books, study materials, and
                        essential resources to support academic growth. The
                        library provides a quiet and comfortable environment,
                        encouraging students to develop reading habits and
                        enhance their knowledge.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A well-organized collection of books covering
                            various subjects, supporting
                          </span>
                          <br />
                          <span>
                            students' academic needs and general reading
                            interests.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A quiet and comfortable environment that encourages
                            reading, learning, and
                          </span>
                          <br />
                          <span>focused study for students of all grades.</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Basic reference materials and textbooks available
                            for students to assist
                          </span>
                          <br />
                          <span>
                            with homework, projects, and exam preparation.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Seating areas designed for individual study as well
                            as group discussions
                          </span>
                          <br />
                          <span>and collaborative learning.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12"></Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Library;
