import React from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/rules';

const Rules = () => {
    const rules = [
        {
            id: 1,
            title: "School Timings",
            rules: [
                "School functions from 9:30 AM to 3:30 PM.",
                "Students must reach the school by 9:15 AM.",
                "Late arrivals must report to the office and get permission to attend class."
            ]
        },
        {
            id: 2,
            title: "Uniform and Grooming",
            rules: [
                "Students must wear the prescribed school uniform neatly and properly.",
                "Boys must have short and well-groomed hair, while girls must keep their hair tied properly.",
                "Wearing accessories such as fancy earrings, bracelets, and makeup is not allowed."
            ]
        },
        {
            id: 3,
            title: "Attendance and Leave",
            rules: [
                "A minimum of 85% attendance is required for promotion.",
                "Leave applications must be submitted in advance with a valid reason.",
                "Medical leave must be supported by a doctor’s certificate."
            ]
        },
        {
            id: 4,
            title: "Discipline and Conduct",
            rules: [
                "Students must show respect to teachers, staff, and fellow students.",
                "The use of mobile phones inside the school premises is strictly prohibited.",
                "Maintaining cleanliness in classrooms and school premises is mandatory."
            ]
        },
        {
            id: 5,
            title: "Academics and Assignments",
            rules: [
                "Students must complete homework and assignments on time.",
                "Bringing textbooks, notebooks, and required study materials every day is compulsory.",
                "Participation in academic and co-curricular activities is encouraged."
            ]
        }
    ];

    return (
        <Styles>
            <div className="main-wrapper instructor-details-page">
                <HeaderTwo />
                <BreadcrumbBox title="School Rules and Regulations" />

                <section className="instructor-details-area">
                    <Styles>
                        <div className="school-rules-page">
                            <div className="rules-area">
                                <div className="container">
                                    <div className="section-title">
                                        <h1>School Rules and Regulations</h1>
                                    </div>

                                    {rules.map((section) => (
                                        <div key={section.id} className="rules-section">
                                            <div className="section-header">
                                                <h3>{section.title}</h3>
                                            </div>
                                            <div className="rules-list">
                                                <ul>
                                                    {section.rules.map((rule, index) => (
                                                        <li key={index}>
                                                            <div className="rule-number">{index + 1}</div>
                                                            <div className="rule-content">{rule}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="rules-note">
                                        <h4>Important Note:</h4>
                                        <p>Failure to adhere to these rules may result in disciplinary action as per the school policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Styles>
                </section>

                <FooterTwo />
            </div>
        </Styles>
    );
};

export default Rules;
