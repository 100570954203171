import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="why">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="why">
                        <i className="las la-arrow-right"></i> Why Mystical Rose
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mission">
                        <i className="las la-arrow-right"></i> Our Mission
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="vision">
                        <i className="las la-arrow-right"></i> Our Vision
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Our Campus
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      {/* <Nav.Link eventKey="research">
                        <i className="las la-arrow-right"></i> Our Research
                      </Nav.Link> */}
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="why">
                      <h4 className="tab-title">Why Mystical Rose</h4>
                      <p className="tab-desc">
                        who are experts in their field and strive to inspire and
                        spark curiosity in each of their students. The School is
                        home to a collaborative, supportive, diverse, and
                        ever-growing community more like a family than a school.
                        School prides itself on academic achievement and
                        outcomes, global integration, faculty excellence, and a
                        personalized approach to teaching and learning.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>A Supportive Community
                          – Our school fosters a collaborative, diverse, and
                          ever-growing community that feels more like a family
                          than an institution.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Academic Excellence –
                          We take pride in outstanding academic achievements,
                          ensuring strong learning outcomes and global
                          integration.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Expert Faculty – Our
                          dedicated educators are committed to excellence,
                          providing a personalized approach to teaching and
                          learning.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mission">
                      <h4 className="tab-title">Our Mission</h4>
                      <p className="tab-desc">
                        Our mission is to provide quality education to all
                        especially the marginalized, to serve as a venue for
                        social justice, to instill a firm commitment towards
                        spiritual and moral values developed skills, talents and
                        self-esteem, and give response to the special needs of
                        individual students.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Inclusive Quality
                          Education – We are committed to providing quality
                          education for all, with a special focus on the
                          marginalized, ensuring equal learning opportunities.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Holistic Development –
                          We nurture skills, talents, and self-esteem,
                          addressing the unique needs of each student to help
                          them reach their full potential.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Social Justice &
                          Ethical Values – We serve as a platform for social
                          justice while fostering strong spiritual and moral
                          values in our students.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="vision">
                      <h4 className="tab-title">Our Vision</h4>
                      <p className="tab-desc">
                        Our vision is to bring forth to the society a young
                        generation imbibing faith in God, equipped with the most
                        modern knowledge and possessing the best of the
                        interpersonal relationship and duties towards the
                        society at large.Take shape and becomes a reality
                        through each and every student.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Faith & Values – To
                          nurture a young generation rooted in faith in God,
                          guided by strong moral and ethical principles.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Knowledge & Growth – To
                          equip students with modern knowledge and skills,
                          preparing them for a dynamic world.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Social Responsibility –
                          To develop responsible individuals who excel in
                          interpersonal relationships and contribute
                          meaningfully to society.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Our Campus</h4>
                      <p className="tab-desc">
                        The school campus is full of nature’s blessings with
                        blooming buds, sprouting leaves waving branches together
                        with the vastness of the desert. The main block is
                        situated in a 3 acre area very attractively colored and
                        durably maintained.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Scenic & Serene Environment – A beautiful blend of nature’s blessings, with blooming flowers, lush greenery, and the vast expanse of the desert creating a peaceful learning atmosphere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Spacious & Well-Designed Campus – The main block is spread over 3 acres, offering ample space for academics, sports, and extracurricular activities.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Aesthetic & Durable Infrastructure – The school features an attractively designed, well-maintained campus that ensures both visual appeal and long-term sustainability.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="research">
                      <h4 className="tab-title">Our Research</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBox;
