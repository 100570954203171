import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/instructor.js";
import TabBox from "../../components/about/TabBox.js";
import TestimonialSlider from "../../components/about/TestimonialSlider.js";
import FaqEvent from "../../components/FaqEvent";

class Mission extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper instructor-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Instructor Details" />

          {/* Instructor Details Area */}
          <section className="instructor-details-area">
            <Container>
              <Row>
                <Col md="4">
                  <div className="instructor-img">
                    <img
                      src={process.env.PUBLIC_URL + `/assets/Home/Discipline.webp`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col md="8">
                  <div className="instructor-content">
                    <h4>Mission and Vision</h4>
                    {/* <span>Senior Teacher</span> */}
                    <p>
                      VISSION Our mission is to provide high-quality education
                      to all, with a special focus on the marginalized, ensuring
                      that every student has access to knowledge and
                      opportunities for growth. We strive to serve as a platform
                      for social justice, advocating for equality and
                      inclusivity in education. Our institution is committed to
                      instilling strong spiritual and moral values, fostering
                      integrity, compassion, and a sense of responsibility in
                      our students. We emphasize the development of essential
                      skills, talents, and self-esteem, empowering students to
                      confidently face life’s challenges. Additionally, we
                      recognize and respond to the unique needs of each student,
                      offering personalized support and guidance to help them
                      achieve academic and personal excellence.
                      <br />
                      <br />
                      Our vision is to nurture a generation that embodies faith
                      in God, is well-versed in modern knowledge, and excels in
                      interpersonal relationships while fulfilling their
                      responsibilities toward society. We aspire to shape
                      individuals who are not only intellectually competent but
                      also spiritually enriched, morally upright, and socially
                      responsible. Our goal is to create an environment where
                      every student is empowered to develop their full
                      potential, ensuring that our vision takes shape and
                      becomes a reality through their actions and contributions
                      to the world.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <TabBox />
          <TestimonialSlider />
          <FaqEvent />

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Mission;
