import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/contact.js";

function Contact() {
  useEffect(() => {
    const form = document.getElementById("form_contact");
    const name = document.getElementById("contact_name");
    const email = document.getElementById("contact_email");
    const subject = document.getElementById("contact_subject");
    const message = document.getElementById("contact_message");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();

      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const subjectValue = subject.value.trim();
      const messageValue = message.value.trim();

      if (nameValue === "") {
        setError(name, "Name can't be blank");
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
      } else {
        setSuccess(email);
      }

      if (subjectValue === "") {
        setError(subject, "Subject can't be blank");
      } else {
        setSuccess(subject);
      }

      if (messageValue === "") {
        setError(message, "Message can't be blank");
      } else {
        setSuccess(message);
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".contact_input-msg");
      formControl.className = "form-control text-left error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper contact-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Contact Us" />

        {/* Contact Area */}
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="4">
                <div className="contact-box-title">
                  <h4>Contact Info</h4>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-map-marker"></i>
                  </div>
                  <div className="box-content">
                    <h5>Our Location</h5>
                    <p>
                      Mystical Rose School, Vettucaud, Titanium Trivadrum
                      ,695021
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="box-content">
                    <h5>Email Address</h5>
                    <p>
                      rosamysticalcbse@gmail.com
                      <br />
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-phone"></i>
                  </div>
                  <div className="box-content">
                    <h5>Phone Number</h5>
                    <p>
                      +949 671 2142
                      <br />
                    </p>
                  </div>
                </div>
                {/* <div className="contact-social">
                                    <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                    </ul>
                                </div> */}
              </Col>
              <Col md="8">
                <div className="contact-form">
                  <div className="form-title">
                    <h4>Get In Touch</h4>
                  </div>
                  <div className="form-box">
                    <form id="form_contact" className="form">
                      <Row>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Full Name"
                              id="contact_name"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="email"
                              placeholder="Email Address"
                              id="contact_email"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Subject"
                              id="contact_subject"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <textarea
                              name="message"
                              id="contact_message"
                              placeholder="Enter Message"
                            ></textarea>
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <button>Send Message</button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Google Map */}
        </section>
        <iframe 
        title = "map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4017335.4463131754!2d73.30278172537174!3d10.486539753313819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bc6ebff15b15%3A0x5ce4cd29cf551dd0!2sMystical%20Rose%20Convent%20School!5e0!3m2!1sen!2sin!4v1739867008364!5m2!1sen!2sin"
    width="100%"
    height="450"
    style={{ border: "0", borderRadius:"10px"}}
    allowFullScreen
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
/>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
}

export default Contact;
