import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";

function StickyMenu({navLinks}) {
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });

    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/Home/logo1.png"} alt="" /></Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="menu-box d-flex justify-content-end">
                                <ul className="nav menu-nav">
                                    {navLinks?.map((data) => (
                                        
                                 
                                    <li className="nav-item dropdown active" key={data.name}>
                                        <Link className="nav-link dropdown-toggle" to={`${process.env.PUBLIC_URL}${data.href}`} data-toggle="dropdown">{data?.name} {data?.subMenu && (<i className="las la-angle-down"></i>)}</Link>
                                        <ul className="dropdown list-unstyled">
                                            {data?.subMenu?.map((subMenu) => (
                                                
                                         
                                            <li className="nav-item" key={subMenu?.name}><Link className="nav-link" to={`${process.env.PUBLIC_URL}${subMenu.href}`}>{subMenu?.name}</Link></li>
                                            ))}
                                        </ul>
                                      
                                    </li>
                                       ))}
                                    </ul>
                                {/* <div className="apply-btn">
                                    <Link to={process.env.PUBLIC_URL + "/registration"}><i className="las la-clipboard-list"></i>Apply Now</Link>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default StickyMenu