import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";

import HeaderTwo from "../../components/HeaderTwo.js";
import FooterTwo from "../../components/FooterTwo.js";

class Auditorium extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcrumb */}
          <BreadcrumbBox title="Auditorium" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Spacious Auditorium for Events and Activities</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-theater-masks"></i> Spacious
                          Seating Arrangement
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-bullhorn"></i> High-Quality Sound
                          System
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-lightbulb"></i> Advanced Lighting
                          Setup
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/Home/auditorium.webp`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Library Overview</h5>
                      <p>
                        Our auditorium is a versatile space designed for a
                        variety of events and activities. It provides a
                        comfortable and well-equipped environment for students
                        to engage in school functions, performances, seminars,
                        and workshops. With ample seating and modern sound and
                        lighting systems, it offers a great setting for both
                        academic and extracurricular events.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A spacious auditorium designed for a variety of
                            events, offering ample
                          </span>
                          <br />
                          <span>
                            seating for students, staff, and guests during
                            school functions and performances.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Equipped with modern sound systems and advanced
                            lighting, the auditorium
                          </span>
                          <br />
                          <span>
                            provides the ideal setting for presentations,
                            performances, and seminars.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A flexible space for hosting various extracurricular
                            activities, including
                          </span>
                          <br />
                          <span>
                            school assemblies, cultural events, and talent
                            shows.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Accommodates large audiences comfortably, ensuring
                            an engaging experience
                          </span>
                          <br />
                          <span>for both performers and spectators.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12"></Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  }
}

export default Auditorium;
